import gql from 'graphql-tag';

export const softledger = gql`
  query softledger(
    $endpoint: String!
    $limit: Int!
    $offset: Int!
    $filters: GenericScalar
  ) {
    softledger(
      endpoint: $endpoint
      limit: $limit
      offset: $offset
      filters: $filters
    ) {
      totalItems
      data
    }
  }
`;
export const mixpanel = gql`
  query mixpanel($endpoint: String!, $filters: GenericScalar) {
    mixpanel(endpoint: $endpoint, filters: $filters) {
      data
    }
  }
`;
export const sendgrid = gql`
  query sendgrid(
    $endpoint: String!
    $limit: Int!
    $offset: Int!
    $filters: GenericScalar
  ) {
    sendgrid(
      endpoint: $endpoint
      limit: $limit
      offset: $offset
      filters: $filters
    ) {
      data
    }
  }
`;
export const location = gql`
  query locations($limit: Int!, $offset: Int!, $filters: GenericScalar) {
    locations(limit: $limit, offset: $offset, filters: $filters) {
      edges {
        node {
          id
          name
          slLocationId
        }
      }
    }
  }
`;

export const updateGiftExchange = gql`
  mutation updateGiftExchange(
    $id: Int!
    $status: Boolean!
    $giftData: String!
  ) {
    updateGiftExchange(id: $id, status: $status, giftData: $giftData)
  }
`;

export const getGiftExchangeList = gql`
  {
    getGiftExchangeAdmin {
      id
      status
      giftData
      exchangeType
      exchangeTypeLabel
      name
      createdAt
      updatedAt
      takenPoints
    }
  }
`;
