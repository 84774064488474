import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/firestore"
import 'firebase/messaging';
// import { config } from '../config'

const prodConfig = {
  apiKey: 'AIzaSyAZIQvo58ynQ9TSvmuW_NOlYCZ_q4nSR0Q',
  authDomain: 'leasera-production.firebaseapp.com',
  databaseURL: 'https://leasera-production.firebaseio.com',
  projectId: 'leasera-production',
  storageBucket: 'leasera-production.appspot.com',
  messagingSenderId: '913859279590',
  appId: "1:662469587140:web:63efd01be202870886d991",
  measurementId: "G-F6KJQNR5C4"
  }
const app = firebase.initializeApp(prodConfig)

export const refresh = firebase.auth()


export const auth = app.auth()


export default app