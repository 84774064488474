import React , {useState,useEffect} from "react";
import { Tab, Table, Header, Segment, Input, Loader, Dimmer, Icon, Button } from 'semantic-ui-react'
import { DASHBOARD,NUMBERFORMAT } from '../../data/constants';
import { sendgrid } from "../../data/endpoints/gql";
import { getClient } from "../../data/init-apollo";
import moment from 'moment';
import  SendgridGraph  from './SendgridGraph';

const SendgridClient = getClient(DASHBOARD);
function Sendgrid() {
  const [SendgridData, setSendgridData] = useState([]);
  const [chosenSort, setChosenSort] = useState('');
  const [direction, setDirection] = useState(false);
  const [loader, setLoader] = useState(false);
  const [title, setTitle] = useState(false);
  const [visualize, setVisualize] = useState(false);
  const [toEmail, setToEmail] = useState(false);
  const [fromEmail, setFromEmail] = useState(false);

    const querySendgrid = (end, filter) => {
      setTitle(`${end} - ${'Messages Interactions'}`)
      console.log(toEmail)
      setLoader(true)
      SendgridClient
        .query({
          query: sendgrid,
          variables: {
            limit:1000,
            offset: 0,
            endpoint:end,
            filters: {
              toEmail:toEmail,
              fromEmail:fromEmail
            }
          },
        })
        .then((res) => {
          const SendgridData = res.data.sendgrid.data
          setSendgridData(SendgridData)
          setLoader(false)
        })
        .catch((error) => {
          setLoader(false)
        });
    }
  const sortCol = (sortVal) => {
    switch(sortVal){
      case "From":
        setChosenSort(sortVal)
        direction?setDirection(false):setDirection(true)
        setSendgridData(direction?SendgridData.sort((a, b) => a.from_email.localeCompare(b.from_email)):SendgridData.reverse((a, b) => a.from_email.localeCompare(b.from_email)))
      break;
      case "To":
        setChosenSort(sortVal)
        direction?setDirection(false):setDirection(true)
        setSendgridData(direction?SendgridData.sort((a, b) => a.to_email.localeCompare(b.to_email)):SendgridData.reverse((a, b) => a.to_email.localeCompare(b.to_email)))
      break;
      case "Status":
        setChosenSort(sortVal)
        direction?setDirection(false):setDirection(true)
        setSendgridData(direction?SendgridData.sort((a, b) => a.status.localeCompare(b.status)):SendgridData.reverse((a, b) => a.status.localeCompare(b.status)))
      break;
      case "Time":
        setChosenSort(sortVal)
        direction?setDirection(false):setDirection(true)
        setSendgridData(direction?SendgridData.sort((a, b) => new Date(a.last_event_time) - new Date(b.last_event_time)):SendgridData.reverse((a, b) => new Date(a.last_event_time) - new Date(b.last_event_time)))
      break;
      case "Subject":
        setChosenSort(sortVal)
        direction?setDirection(false):setDirection(true)
        setSendgridData(direction?SendgridData.sort((a, b) => a.subject.localeCompare(b.subject)):SendgridData.reverse((a, b) => a.subject.localeCompare(b.subject)))
      break;
      case "Opens":
        setChosenSort(sortVal)
        direction?setDirection(false):setDirection(true)
        setSendgridData(direction?SendgridData.sort((a, b) => parseFloat(a.opens_count) - parseFloat(b.opens_count)):SendgridData.reverse((a, b) => parseFloat(a.opens_count) - parseFloat(b.opens_count)))
      break;
      case "Clicks":
        setChosenSort(sortVal)
        direction?setDirection(false):setDirection(true)
        setSendgridData(direction?SendgridData.sort((a, b) => parseFloat(a.clicks_count) - parseFloat(b.clicks_count)):SendgridData.reverse((a, b) => parseFloat(a.clicks_count) - parseFloat(b.clicks_count)))
      break;
    }
  }
  
  
  return (
    <div className="Sendgrid">
      <Segment>
        <Input placeholder='To' onChange={(e)=> setToEmail(e.target.value)} />&nbsp;&nbsp;
        <Input placeholder='From'  onChange={(e)=> setFromEmail(e.target.value)} />&nbsp;&nbsp;
        <Button onClick={() => querySendgrid("messages")} disabled={!toEmail &&!fromEmail}>Search</Button>
        <Button floated="right" onClick={() => setVisualize(!visualize?true:false)}>Visualize</Button>
      </Segment> 
      {
        visualize?
       <SendgridGraph title={title} SendgridData={SendgridData}/>
        :
      <Segment className="table-segment-global">
      {
        loader?
        <>
        <Dimmer active>
          <Loader />
        </Dimmer>
        </>
        :
        <>
      <Header textAlign="center" className="header-global">{title}</Header>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="3" onClick={() => sortCol("From")}>From{chosenSort =="From" &&<Icon name={`arrow alternate circle ${direction?"up":"down"}`} />}</Table.HeaderCell>
            <Table.HeaderCell width="3" onClick={() => sortCol("To")}>To {chosenSort =="To" &&<Icon name={`arrow alternate circle ${direction?"up":"down"}`} />}</Table.HeaderCell>
            <Table.HeaderCell width="1" onClick={() => sortCol("Status")} >Status {chosenSort =="Status" &&<Icon name={`arrow alternate circle ${direction?"up":"down"}`} />}</Table.HeaderCell>
            <Table.HeaderCell width="2" onClick={() => sortCol("Time")}>Time {chosenSort =="Time" &&<Icon name={`arrow alternate circle ${direction?"up":"down"}`} />}</Table.HeaderCell>
            <Table.HeaderCell width="5" onClick={() => sortCol("Subject")}>Subject {chosenSort =="Subject" &&<Icon name={`arrow alternate circle ${direction?"up":"down"}`} />}</Table.HeaderCell>
            <Table.HeaderCell width="1" onClick={() => sortCol("Opens")}>Opens {chosenSort =="Opens" &&<Icon name={`arrow alternate circle ${direction?"up":"down"}`} />}</Table.HeaderCell>
            <Table.HeaderCell width="1" onClick={() => sortCol("Clicks")}>Clicks {chosenSort =="Clicks" &&<Icon name={`arrow alternate circle ${direction?"up":"down"}`} />}</Table.HeaderCell>

          </Table.Row>
        </Table.Header>
      </Table>
      <div className='scrollable-table-global'>
      <Table celled compact striped>
        <Table.Body>
        {
          SendgridData.map(data => {
          return (
            <Table.Row>
              <Table.Cell width="3">{data.from_email}</Table.Cell>
              <Table.Cell width="3">{data.to_email}</Table.Cell>
              <Table.Cell width="1">{data.status}</Table.Cell>
              <Table.Cell width="2">{data.last_event_time ?moment(data.last_event_time).format("MM/DD/YYYY"):"--"}</Table.Cell>
              <Table.Cell width="5">{data.subject}</Table.Cell>
              <Table.Cell width="1">{data.opens_count}</Table.Cell>
              <Table.Cell width="1">{data.clicks_count}</Table.Cell>
            </Table.Row>
          )
          })
        }
        </Table.Body>
      </Table>
      </div>
      <Table celled >
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell width="3"></Table.HeaderCell>
            <Table.HeaderCell width="3"></Table.HeaderCell>
            <Table.HeaderCell width="1"></Table.HeaderCell>
            <Table.HeaderCell width="2"></Table.HeaderCell>
            <Table.HeaderCell width="5"></Table.HeaderCell>
            <Table.HeaderCell width="1">{NUMBERFORMAT.format(SendgridData.reduce((a,b) => a + b.opens_count,0).toFixed(2))}</Table.HeaderCell>
            <Table.HeaderCell width="1">{NUMBERFORMAT.format(SendgridData.reduce((a,b) => a + b.clicks_count,0).toFixed(2))}</Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      </>
    }
      </Segment>
    }
    </div>
  );
}

export default Sendgrid;
