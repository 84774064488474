import React from 'react';
import { Table, Loader } from 'semantic-ui-react';
import { GIFT_EXCHANGE_URL } from '../../data/constants';
import {
  getGiftExchangeList,
  updateGiftExchange,
} from '../../data/endpoints/gql';
import { getClient } from '../../data/init-apollo';

const giftClient = getClient(GIFT_EXCHANGE_URL);

export default function GiftExchangeList() {
  const [data, setData] = React.useState([]);
  const [textInput, setTextInput] = React.useState({});
  const [isLoading, setIsLoading] = React.useState({});

  const fetchExchangeList = async () => {
    try {
      const response = await giftClient.query({
        query: getGiftExchangeList,
        fetchPolicy: 'no-cache',
      });
      if (response.data.getGiftExchangeAdmin) {
        setData(response.data.getGiftExchangeAdmin);
      }
    } catch (error) {
    } finally {
    }
  };

  React.useEffect(() => {
    fetchExchangeList();
  }, []);

  const handleTextChange = (id) => (e) => {
    const data = { ...textInput };
    data[id] = e.target.value;
    setTextInput(data);
  };

  const fillCouponCode = (id) => async () => {
    try {
      setIsLoading((prev) => ({ ...prev, [id]: true }));

      if (textInput[id] === undefined || textInput[id].length < 3) {
        alert(`You have to fill coupon code for #${id}`);
        return;
      }

      const resp = await giftClient.mutate({
        mutation: updateGiftExchange,
        variables: {
          id,
          status: true,
          giftData: textInput[id],
        },
      });
      if (resp.data.updateGiftExchange.update_status) {
        alert('Gift card added');
      } else {
        alert('There is a problem, gift card not saved!');
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading((prev) => ({ ...prev, [fillCouponCode]: false }));
      fetchExchangeList();
    }
  };

  return (
    <Table celled striped>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Id</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Taken Points</Table.HeaderCell>
          <Table.HeaderCell>Coupon Type</Table.HeaderCell>
          <Table.HeaderCell>Coupon Code</Table.HeaderCell>
          <Table.HeaderCell>Created At</Table.HeaderCell>
          <Table.HeaderCell>Fill Coupon Code</Table.HeaderCell>
          <Table.HeaderCell>Action</Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {data.map((item) => {
          const rowData = {};
          if (item.status) rowData.positive = true;
          if (!item.status) rowData.negative = true;

          return (
            <Table.Row {...rowData}>
              <Table.Cell>#{item.id}</Table.Cell>
              <Table.Cell>{item.name ?? '-'}</Table.Cell>
              <Table.Cell>{!item.status ? 'Pending' : 'Filled'}</Table.Cell>
              <Table.Cell>{item.takenPoints ?? 0}</Table.Cell>
              <Table.Cell>{item.exchangeTypeLabel}</Table.Cell>
              <Table.Cell>{item.giftData}</Table.Cell>
              <Table.Cell>{item.createdAt}</Table.Cell>
              {!item.status && (
                <>
                  <Table.Cell>
                    <input
                      onChange={handleTextChange(item.id)}
                      value={textInput[item.id] ?? ''}
                      disabled={isLoading[item.id] ?? false}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    {isLoading[item.id] === true ? (
                      <Loader active inline />
                    ) : (
                      <button onClick={fillCouponCode(item.id)}>Save</button>
                    )}
                  </Table.Cell>
                </>
              )}
            </Table.Row>
          );
        })}
      </Table.Body>
    </Table>
  );
}
