import React, { useState, useEffect } from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import Accounting from './components/Accounting/Accounting';
import Navigation from './components/Navigation/Navigation';
import Sendgrid from './components/Sendgrid/Sendgrid';
import Actions from './components/Actions/Actions';
import Mixpanel from './components/Mixpanel/Mixpanel';
import MixpanelDetails from './components/Mixpanel/MixpanelDetails';
import 'semantic-ui-css/semantic.min.css';
import { getCookie, TOKEN } from './data/constants';
import { Button, Header, Icon, Modal, Input, Image } from 'semantic-ui-react';
import Cookie from 'js-cookie';
import { auth } from './components/Auth/Firebase';
import Toastify from 'toastify-js';
import 'toastify-js/src/toastify.css';
import GiftExchangeList from './components/GiftExchangeList/GiftExchangeList';

const withRouter = (Component) => {
  const Wrapper = (props) => {
    const history = useNavigate();
    return <Component history={history} {...props} />;
  };
  return Wrapper;
};
function App() {
  const cookie = getCookie();
  const [open, setOpen] = useState(false);
  const [primaryLocation, setPrimaryLocation] = useState(false);
  const [email, setEmail] = useState(false);
  const [password, setPassword] = useState(false);
  const registerAuthToken = (token) => {
    Cookie.set(TOKEN, token, {
      expires: new Date(new Date().getTime() + 15 * 60 * 1000),
    });
    setOpen(true);
  };

  useEffect(() => {
    if (getCookie()) {
      setOpen(true);
      setEmail(false);
      setPassword(false);
    } else setOpen(false);
  }, [getCookie(), open]);

  const loginAction = () => {
    if (email == 'admin@leasera.com') {
      auth
        .signInWithEmailAndPassword(email, password)
        .then(async () => {
          auth.currentUser.getIdTokenResult().then(async (idTokenResult) => {
            const token = await auth.currentUser.getIdToken(true);
            return registerAuthToken(token);
          });
        })
        .catch((e) => {
          Toastify({
            text: 'Wrong email or password',
            className: 'info',
            style: {
              background: 'linear-gradient(to right, #00b09b, #96c93d)',
            },
          }).showToast();
        });
    } else {
      Toastify({
        text: 'Wrong email or password',
        className: 'info',
        style: {
          background: 'linear-gradient(to right, #00b09b, #96c93d)',
        },
      }).showToast();
    }
  };

  return (
    // <Router>
    <>
      <Navigation setPrimaryLocation={setPrimaryLocation} />
      <div className="loose-container">
        {getCookie() ? (
          <>
            <Routes>
              <Route
                render={(props) =>
                  Boolean(getCookie()) && <Home {...props} role={cookie} />
                }
              />
              <Route
                path="/"
                element={<Home primaryLocation={primaryLocation} />}
              />
              <Route
                path="/Accounting"
                element={<Accounting primaryLocation={primaryLocation} />}
              />
              <Route path="/Mixpanel" element={<Mixpanel />} />
              <Route
                path="/MixpanelDetails/:userId"
                element={<MixpanelDetails />}
              />
              <Route path="/Sendgrid" element={<Sendgrid />} />
              <Route path="/Actions" element={<Actions />} />
              <Route path="/GiftExchangeList" element={<GiftExchangeList />} />
            </Routes>
          </>
        ) : (
          <Modal className="semanticModal" basic open={true} size="small">
            <Header image textAlign="center">
              <Image
                src="images/logo-white-1-alt.svg"
                size="massive"
                className="leaseralogo"
              />
            </Header>
            <Modal.Content style={{ width: '100%' }}>
              <div className="logininputs">
                <Input
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                ></Input>
                <Input
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                ></Input>
                <Button color="green" inverted onClick={() => loginAction()}>
                  <Icon name="checkmark" /> Sign In
                </Button>
              </div>
            </Modal.Content>
          </Modal>
        )}
      </div>
    </>
  );
}

export default withRouter(App);
