import React,{ useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Button, Card, Image, Header, Dropdown, Loader, Dimmer, } from 'semantic-ui-react'
import { NUMBERFORMAT } from '../../data/constants';
import moment from 'moment';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function SendgridGraph(props) {
  const [Year, setYear] = useState("2022");
  const [loader, setLoader] = useState(false);
  const arraydf = [
    {month:moment(`${Year}-01-01`), value:0},
    {month:moment(`${Year}-02-02`), value:0},
    {month:moment(`${Year}-03-03`), value:0},
    {month:moment(`${Year}-04-04`), value:0},
    {month:moment(`${Year}-05-05`), value:0},
    {month:moment(`${Year}-06-06`), value:0},
    {month:moment(`${Year}-07-07`), value:0},
    {month:moment(`${Year}-08-08`), value:0},
    {month:moment(`${Year}-09-09`), value:0},
    {month:moment(`${Year}-10-10`), value:0},
    {month:moment(`${Year}-11-11`), value:0},
    {month:moment(`${Year}-12-12`), value:0},
  ]
  const [SendgridGraph, setSendgridGraph] = useState(arraydf);
  
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,

      },
    },
  };

  const changeYear = (e) => {
    setYear(e.target.textContent)
  }

  useEffect(() => {
    setLoader(true)
    addValues(Year)
    setLoader(false)
  },[Year]);

  useEffect(() => {
    setLoader(true)
    addValues(props.SendgridData)
    setLoader(false)
  },[props.SendgridData]);

  
  
  const addValues = () => {
     const updateAccount = arraydf.map((item) => {
      const tempItem = item;
      props.SendgridData.forEach((item2)=>{
        const numberize = parseInt(item2.amount)
        if(moment(tempItem.month).isSame(moment(item2.last_event_time),'month')){
          tempItem.value += 1
        }
      })
      return tempItem
    })
    setSendgridGraph(updateAccount)
  }

  const dataUsers = {
    labels:SendgridGraph.map(item =>item.month.format('MMMM')),
    datasets: [
      {
        label: props.title,
        data: SendgridGraph.map(item =>item.value),
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };
const yearOptions = [
  {
    key: '2022',
    text: '2022',
    value: '2022',
  },
  {
    key: '2021',
    text: '2021',
    value: '2021',
  },
  {
    key: '2020',
    text: '2020',
    value: '2020',
  },
  {
    key: '2019',
    text: '2019',
    value: '2019',
  },
  {
    key: '2018',
    text: '2018',
    value: '2018',
  },

]

  return (
    <div className="home">
        <Card.Group itemsPerRow={1} stackable>
            <Card>
                <Card.Content>
                    <Card.Header className="header-global" textAlign="center">{props.title}  
                    <Dropdown
                      placeholder='Select Year'
                      selection
                      defaultValue={yearOptions[0].value}
                      onChange={(e)=> changeYear(e)}
                      options={yearOptions}
                    /></Card.Header>
                </Card.Content>
                <Card.Content  className="table-segment-global" extra>
                  {
                    loader?
                    <>
                  <Dimmer active>
                    <Loader />
                  </Dimmer>
                  </>
                  :
                  <Line options={options} data={dataUsers} />
                }
                </Card.Content>
            </Card>
        </Card.Group>
    </div>
  );
}

export default SendgridGraph;