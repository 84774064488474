import React , {useState,useEffect} from "react";
import { Tab, Table, Header, Segment, Dropdown, Loader, Dimmer, Icon, Button } from 'semantic-ui-react'
import { DASHBOARD,NUMBERFORMAT } from '../../data/constants';
import { mixpanel } from "../../data/endpoints/gql";
import { getClient } from "../../data/init-apollo";
import moment from 'moment';
import  MixpanelGraph  from './MixpanelGraph';
import { NavLink } from "react-router-dom";
import './Mixpanel.css'

const MixapanelClient = getClient(DASHBOARD);
function Mixpanel() {
  const [MixpanelData, setMixpanelData] = useState([]);
  const [MixpanelDataEvents, setMixpanelDataEvents] = useState([]);
  const [chosenSort, setChosenSort] = useState('');
  const [direction, setDirection] = useState(false);
  const [detailsToggle, setDetailsToggle] = useState(false);
  const [loader, setLoader] = useState(false);
  const [title, setTitle] = useState(false);
  const [visualize, setVisualize] = useState(false);

    const queryMixapanel = (end, filter,name) => {
      setTitle(`${end} - ${name?name:"Users"} - ${filter?filter:"Users"}`)
      setLoader(true)
      MixapanelClient
        .query({
          query: mixpanel,
          
          variables: {
            filters:filter?{
              distinct_ids: [filter],
              from_date: "2000-01-01",
              to_date: "2025-02-07"
            }:{},
            endpoint:end,
          },
        })
        .then((res) => {
          const MixpanelData = res.data.mixpanel.data
          if(filter){
            setMixpanelDataEvents(MixpanelData)
            setLoader(false)
          }
          else {
            setMixpanelData(MixpanelData)
            setLoader(false)
          }
        })
        .catch((error) => {
          setLoader(false)
        });
    }
  const sortCol = (sortVal) => {
    switch(sortVal){
      case "Name":
        setChosenSort(sortVal)
        direction?setDirection(false):setDirection(true)
        setMixpanelData(direction?MixpanelData.sort((a, b) => a.$properties.Name.localeCompare(b.$properties.Name)):MixpanelData.reverse((a, b) => a.$properties.Name.localeCompare(b.$properties.Name)))
      break;
      case "Event":
        setChosenSort(sortVal)
        direction?setDirection(false):setDirection(true)
        setMixpanelData(direction?MixpanelData.sort((a, b) => a.event.localeCompare(b.event)):MixpanelData.reverse((a, b) => a.event.localeCompare(b.event)))
      break;
      case "URL":
        setChosenSort(sortVal)
        direction?setDirection(false):setDirection(true)
        setMixpanelData(direction?MixpanelData.sort((a, b) => a.properties.$current_url.localeCompare(b.properties.$current_url)):MixpanelData.reverse((a, b) => a.properties.$current_url.localeCompare(b.properties.$current_url)))
      break;
      case "Time":
        setChosenSort(sortVal)
        direction?setDirection(false):setDirection(true)
        setMixpanelData(direction?MixpanelData.sort((a, b) => new Date(a.properties.time).toLocaleDateString("en-US") - new Date(b.properties.time).toLocaleDateString("en-US")):MixpanelData.reverse((a, b) => new Date(a.properties.time).toLocaleDateString("en-US") - new Date(b.properties.time).toLocaleDateString("en-US")))
      break;
      case "Email":
        setChosenSort(sortVal)
        direction?setDirection(false):setDirection(true)
        setMixpanelData(direction?MixpanelData.sort((a, b) => a.$properties.Email.localeCompare(b.$properties.Email)):MixpanelData.reverse((a, b) => a.$properties.Email.localeCompare(b.$properties.Email)))
      break;
      case "City":
        setChosenSort(sortVal)
        direction?setDirection(false):setDirection(true)
        setMixpanelData(direction?MixpanelData.sort((a, b) => a.$properties.$city.localeCompare(b.$properties.$city)):MixpanelData.reverse((a, b) => a.$properties.$city.localeCompare(b.$properties.$city)))
      break;
      case "Country":
        setChosenSort(sortVal)
        direction?setDirection(false):setDirection(true)
        setMixpanelData(direction?MixpanelData.sort((a, b) => a.$properties.$country_code.localeCompare(b.$properties.$country_code)):MixpanelData.reverse((a, b) => a.$properties.$country_code.localeCompare(b.$properties.$country_code)))
      break;
      case "Seen":
        setChosenSort(sortVal)
        direction?setDirection(false):setDirection(true)
        setMixpanelData(direction?MixpanelData.sort((a, b) => new Date(a.$properties.$last_seen) - new Date(b.$properties.$last_seen)):MixpanelData.reverse((a, b) => new Date(a.$properties.$last_seen) - new Date(b.$properties.$last_seen)))
      break;
      case "Points":
        setChosenSort(sortVal)
        direction?setDirection(false):setDirection(true)
        setMixpanelData(direction?MixpanelData.sort((a, b) => parseFloat(a.$properties.Points) - parseFloat(b.$properties.Points)):MixpanelData.reverse((a, b) => parseFloat(a.$properties.Points) - parseFloat(b.$properties.Points)))
      break;
    }
  }

  const toggleDetails = (id,name) => {
    setDetailsToggle(true)
    queryMixapanel('events', id, name)
  }
  
  
  return (
    <div className="Mixapanel">
      <Segment>
        <Dropdown text='Users' className="dropdown-global">
          <Dropdown.Menu>
            <Dropdown.Item text='Users'  onClick={() => queryMixapanel("users")}/>
          </Dropdown.Menu>
        </Dropdown>
        <Button floated="right" onClick={() => setVisualize(!visualize?true:false)}>Visualize</Button>
      </Segment> 
      {
        visualize?
       <MixpanelGraph title={title} MixpanelData={MixpanelData} MixpanelDataEvents={MixpanelDataEvents} detailsToggle={detailsToggle}/>
        :
      <Segment className="table-segment-global">
      {
        loader?
        <>
        <Dimmer active>
          <Loader />
        </Dimmer>
        </>
        :
        <>
      <Header textAlign="center" className="header-global">{title} {detailsToggle&&<Button floated="right" onClick={()=> setDetailsToggle(false)}>Back</Button>}</Header>
      {
        !detailsToggle?
      <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="3" onClick={() => sortCol("Name")}>Name {chosenSort =="Name" &&<Icon name={`arrow alternate circle ${direction?"up":"down"}`} />}</Table.HeaderCell>
            <Table.HeaderCell width="3" onClick={() => sortCol("Email")}>Email {chosenSort =="Email" &&<Icon name={`arrow alternate circle ${direction?"up":"down"}`} />}</Table.HeaderCell>
            <Table.HeaderCell width="3" onClick={() => sortCol("City")} >City {chosenSort =="City" &&<Icon name={`arrow alternate circle ${direction?"up":"down"}`} />}</Table.HeaderCell>
            <Table.HeaderCell width="3" onClick={() => sortCol("Country")}>Country {chosenSort =="Country" &&<Icon name={`arrow alternate circle ${direction?"up":"down"}`} />}</Table.HeaderCell>
            <Table.HeaderCell width="3" onClick={() => sortCol("Seen")}>Seen {chosenSort =="Seen" &&<Icon name={`arrow alternate circle ${direction?"up":"down"}`} />}</Table.HeaderCell>
            <Table.HeaderCell width="3" onClick={() => sortCol("Points")}>Points {chosenSort =="Points" &&<Icon name={`arrow alternate circle ${direction?"up":"down"}`} />}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      </Table>
      <div className='scrollable-table-global'>
      <Table celled compact striped>
        <Table.Body>
        {
          MixpanelData.map(data => {
          return (
            <Table.Row>
              <Table.Cell width="3"><span className="mix-link" onClick={() => toggleDetails(data.$distinct_id, data.$properties.Name)} >{data.$properties.Name}</span></Table.Cell>
              <Table.Cell width="3">{data.$properties.Email}</Table.Cell>
              <Table.Cell width="3">{data.$properties.$city}</Table.Cell>
              <Table.Cell width="3">{data.$properties.$country_code}</Table.Cell>
              <Table.Cell width="3">{data.$properties.$last_seen ?moment(data.$properties.$last_seen).format("MM/DD/YYYY"):"--"}</Table.Cell>
              <Table.Cell width="3">{data.$properties.Points}</Table.Cell>
            </Table.Row>
          )
          })
        }
        </Table.Body>
      </Table>
      </div>
      <Table celled >
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell width="3"></Table.HeaderCell>
            <Table.HeaderCell width="3"></Table.HeaderCell>
            <Table.HeaderCell width="3"></Table.HeaderCell>
            <Table.HeaderCell width="3"></Table.HeaderCell>
            <Table.HeaderCell width="3"></Table.HeaderCell>
            <Table.HeaderCell width="3">{NUMBERFORMAT.format(MixpanelData.reduce((a,b) => a + b.$properties.Points,0).toFixed(2))}</Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      </>
      :
      <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="6" onClick={() => sortCol("Event")}>Event {chosenSort =="Event" &&<Icon name={`arrow alternate circle ${direction?"up":"down"}`} />}</Table.HeaderCell>
            <Table.HeaderCell width="7" onClick={() => sortCol("URL")}>URL {chosenSort =="URL" &&<Icon name={`arrow alternate circle ${direction?"up":"down"}`} />}</Table.HeaderCell>
            <Table.HeaderCell width="3" onClick={() => sortCol("Time")} >Time {chosenSort =="Time" &&<Icon name={`arrow alternate circle ${direction?"up":"down"}`} />}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      </Table>
      <div className='scrollable-table-global'>
      <Table celled compact striped>
        <Table.Body>
        {
          MixpanelDataEvents.map(data => {
            var dateTime = new Date(data.properties.time * 1000);
          return (
            <Table.Row>
              <Table.Cell width="6">{data.event}</Table.Cell>
              <Table.Cell width="7">{data.properties.$current_url ?data.properties.$current_url: "Python"}</Table.Cell>
              <Table.Cell width="3">{dateTime.toLocaleDateString("en-US")}</Table.Cell>
            </Table.Row>
          )
          })
        }
        </Table.Body>
      </Table>
      </div>
      </>
    }
      </>
    }
      </Segment>
    }
    </div>
  );
}

export default Mixpanel;
