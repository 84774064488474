import React,{ useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Button, Card, Image, Header, Dropdown, Loader, Dimmer, } from 'semantic-ui-react'
import { NUMBERFORMAT } from '../../data/constants';
import moment from 'moment';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function AccountingGraph(props) {
  const [Year, setYear] = useState("2022");
  const [loader, setLoader] = useState(false);
  const arraydf = [
    {month:moment(`${Year}-01-01`), value:0},
    {month:moment(`${Year}-02-02`), value:0},
    {month:moment(`${Year}-03-03`), value:0},
    {month:moment(`${Year}-04-04`), value:0},
    {month:moment(`${Year}-05-05`), value:0},
    {month:moment(`${Year}-06-06`), value:0},
    {month:moment(`${Year}-07-07`), value:0},
    {month:moment(`${Year}-08-08`), value:0},
    {month:moment(`${Year}-09-09`), value:0},
    {month:moment(`${Year}-10-10`), value:0},
    {month:moment(`${Year}-11-11`), value:0},
    {month:moment(`${Year}-12-12`), value:0},
  ]
  const [AccountingGraph, setAccountingGraph] = useState([]);
  
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,

      },
    },
  };

  const changeYear = (e) => {
    setYear(e.target.textContent)
  }

  // useEffect(() => {
  //   setLoader(true)
  //   addValues(Year)
  //   setLoader(false)
  // },[Year]);

  useEffect(() => {
    setLoader(true)
    addValues()
    setLoader(false)
  },[props.AccountingData]);

  
  
  const addValues = () => {
    if(props.AccountingData.length > 0){
      props.AccountingData.forEach(object => {
        const tempArr = arraydf
        const updateAccount = tempArr.map((item) => {
         const tempItem = item;
          object.forEach((item2)=>{
             const numberize = parseInt(item2.amount)
             if(moment(tempItem.month).isSame(moment(item2.invoiceDate),'month')){
               tempItem.value += numberize
             }
         })
         return tempItem
       })
       setAccountingGraph([...AccountingGraph, updateAccount])
      })
    }
    else setAccountingGraph([])
    console.log(AccountingGraph)
  }

  const dataUsers = {
    labels: arraydf.map(month => moment(month.month).format('MMMM')),
    datasets: AccountingGraph.map((item, index )=> ({
        label: props.title[index]&&props.title[index].end + ' - ' + props.title[index]&&props.title[index].filter,
        data: item.map(val => val.value),
        borderColor: getRandomColor() ,
        backgroundColor: getRandomColor() 
    }))
  }
  function getRandomColor() {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

const yearOptions = [
  {
    key: '2022',
    text: '2022',
    value: '2022',
  },
  {
    key: '2021',
    text: '2021',
    value: '2021',
  },
  {
    key: '2020',
    text: '2020',
    value: '2020',
  },
  {
    key: '2019',
    text: '2019',
    value: '2019',
  },
  {
    key: '2018',
    text: '2018',
    value: '2018',
  },

]

  return (
    <div className="home">
        <Card.Group itemsPerRow={1} stackable>
            <Card>
                <Card.Content>
                    <Card.Header className="header-global" textAlign="center">
                    <Dropdown
                      placeholder='Select Year'
                      selection
                      defaultValue={yearOptions[0].value}
                      onChange={(e)=> changeYear(e)}
                      options={yearOptions}
                    /></Card.Header>
                </Card.Content>
                <Card.Content  className="table-segment-global" extra>
                  {
                    loader?
                    <>
                  <Dimmer active>
                    <Loader />
                  </Dimmer>
                  </>
                  :
                  <Line options={options} data={dataUsers} />
                }
                </Card.Content>
            </Card>
        </Card.Group>
    </div>
  );
}

export default AccountingGraph;