import React, {useEffect, useState} from "react";
import { NavLink } from "react-router-dom";
import { Dropdown } from 'semantic-ui-react'
import { location } from "../../data/endpoints/gql";
import { getClient } from "../../data/init-apollo";
import { DASHBOARD } from '../../data/constants';
const locationClient = getClient(DASHBOARD);
function Navigation(props) {
  // Import Faker
  const { faker } = require('@faker-js/faker');
  // Data Set
  const fakerData = [...Array(15)].map((elementInArray, index) => ({
    key: faker.address.city(),
    text: faker.address.city(),
    value: faker.address.city(),
  }))
  const [locations, setLocations] = useState(false);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    queryLocations()
  },[]);

  const queryLocations = () => {
    locationClient
      .query({
        query: location,
        variables: {
          offset:0,
          limit: 1000,
        },
      })
      .then((res) => {
        const loc = res.data.locations.edges
        const Array = loc.map((location) => ({
          key: location.node.slLocationId,
          text: location.node.name,
          value: location.node,
        }))
        setLocations(Array)
      })
      .catch((error) => {
        setLoader(false)
      });
  }
  return (
    <div className="navigation">
      <nav className="navbar navbar-expand navbar-dark bg-dark">
        <div className="container">
          <NavLink className="navbar-brand" to="/">
            Leasera Admin Dashboard
          </NavLink>
            <Dropdown
                placeholder='Select Location'
                fluid
                search
                selection
                onChange={(e, { value }) => props.setPrimaryLocation(value)}
                options={locations}
            />
          <div>
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <NavLink className="nav-link" to="/">
                  Dashboard
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/Accounting">
                  Accounting
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/Mixpanel">
                  Mixpanel
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/Sendgrid">
                  Sendgrid
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/GiftExchangeList">
                  Gift Exchange
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navigation;
