import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import React from 'react';
import { NavLink } from 'react-router-dom';
import './Home.css';
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

function Home(props) {
  return (
    <div className="home1">
      <NavLink
        to="/Accounting"
        style={
          props.primaryLocation
            ? { pointerEvents: 'auto' }
            : { pointerEvents: 'none' }
        }
      >
        <a href="#" class="box">
          <img src="./images/accounting.png" alt="Softledger" />
          <h2>
            <span>Accounting - </span> SoftLedger
          </h2>
        </a>
      </NavLink>
      <NavLink
        to="/Mixpanel"
        style={
          props.primaryLocation
            ? { pointerEvents: 'auto' }
            : { pointerEvents: 'none' }
        }
      >
        <a href="#" class="box">
          <img src="./images/users.png" alt="Mixpanel" />
          <h2>
            <span>Users -</span> Mixpanel
          </h2>
        </a>
      </NavLink>
      <br />
      <NavLink
        to="/Sendgrid"
        style={
          props.primaryLocation
            ? { pointerEvents: 'auto' }
            : { pointerEvents: 'none' }
        }
      >
        <a href="#" class="box">
          <img src="./images/email.png" alt="Sendgrid" />
          <h2>
            <span>Communication -</span> Sendgrid
          </h2>
        </a>
      </NavLink>

      <NavLink
        to="/GiftExchangeList"
        style={
          props.primaryLocation
            ? { pointerEvents: 'auto' }
            : { pointerEvents: 'none' }
        }
      >
        <a href="#" class="box">
          <img src="./images/gift-box.png" alt="GiftExchange" />
          <h2>
            <span>Gift Exchange -</span> Coupon Code
          </h2>
        </a>
      </NavLink>
    </div>
  );
}

export default Home;
