import React , {useState} from "react";
import { Tab, Table, Header } from 'semantic-ui-react'

function Actions() {
  // Import Faker
  const { faker } = require('@faker-js/faker');
  // Data Set
  const fakerData = [...Array(15)].map((elementInArray, index) => ({
    name: faker.name.firstName(),
    number: faker.finance.amount(),
    company: faker.company.companyName(),
  }))
  const [AccountingData, setAccountingData] = useState(fakerData);
  const [chosenSort, setChosenSort] = useState('');
  const [direction, setDirection] = useState(false);
  const sortColAlphabet = (sortVal) => {
    switch(sortVal){
      case "Name":
        setChosenSort(sortVal)
        direction?setDirection(false):setDirection(true)
        setAccountingData(direction?AccountingData.sort((a, b) => a.name.localeCompare(b.name)):AccountingData.reverse((a, b) => a.name.localeCompare(b.name)))
      break;
      case "Amount":
        setChosenSort(sortVal)
        direction?setDirection(false):setDirection(true)
        setAccountingData(direction?AccountingData.sort((a, b) => parseFloat(a.number) - parseFloat(b.number)):AccountingData.reverse((a, b) => parseFloat(a.number) - parseFloat(b.number)))
      break;
      case "Property":
        setChosenSort(sortVal)
        direction?setDirection(false):setDirection(true)
        setAccountingData(direction?AccountingData.sort((a, b) => a.company.localeCompare(b.name)):AccountingData.reverse((a, b) => a.company .localeCompare(b.name)))
      break;
    }
  }

  const panes = [
    { menuItem: 'Renter', render: () => <Tab.Pane>
    <Header>Renter</Header>
     <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell onClick={() => sortColAlphabet("Name")}>Name</Table.HeaderCell>
            <Table.HeaderCell onClick={() => sortColAlphabet("Amount")}>Amount</Table.HeaderCell>
            <Table.HeaderCell onClick={() => sortColAlphabet("Property")}>Property</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
        {
          fakerData.map(data => {
          return (
            <Table.Row>
              <Table.Cell>{data.name}</Table.Cell>
              <Table.Cell>{data.number}</Table.Cell>
              <Table.Cell>{data.company}</Table.Cell>
            </Table.Row>
          )
          })
        }
        </Table.Body>
      </Table>
    </Tab.Pane> },
    { menuItem: 'PM', render: () => <Tab.Pane>
    <Header>PM</Header>
     <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.HeaderCell>Property</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
        {
          fakerData.map(data => {
          return (
            <Table.Row>
              <Table.Cell>{data.name}</Table.Cell>
              <Table.Cell>{data.number}</Table.Cell>
              <Table.Cell>{data.company}</Table.Cell>
            </Table.Row>
          )
          })
        }
        </Table.Body>
      </Table>
    </Tab.Pane> },
    { menuItem: 'Service', render: () => <Tab.Pane>
    <Header>Service</Header>
       <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell onClick={() => sortColAlphabet("Name")}>Name</Table.HeaderCell>
            <Table.HeaderCell onClick={() => sortColAlphabet("Amount")}>Amount</Table.HeaderCell>
            <Table.HeaderCell onClick={() => sortColAlphabet("Property")}>Property</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
        {
          fakerData.map(data => {
          return (
            <Table.Row>
              <Table.Cell>{data.name}</Table.Cell>
              <Table.Cell>{data.number}</Table.Cell>
              <Table.Cell>{data.company}</Table.Cell>
            </Table.Row>
          )
          })
        }
        </Table.Body>
      </Table>
    </Tab.Pane> },
  ]
  
  return (
    <div className="accounting">
    <Tab panes={panes} />
    </div>
  );
}

export default Actions;
