import Cookie from 'js-cookie';
export const NUMBERFORMAT = new Intl.NumberFormat('en-US');
export const TOKEN = 'token';

export const getCookie = () => Cookie.get('token');



export const DASHBOARD = `https://service-leasera-dashboard-${process.env.REACT_APP_PRODUCTION}-uc.a.run.app/leasera_dashboard/graphql`;
export const PERSON_URL = `https://service-person-oudf2y2rza-uc.a.run.app/person/graphql`;
export const GIFT_EXCHANGE_URL = `https://service-gift-exchange-oudf2y2rza-uc.a.run.app/gift_exchange/graphql`;
