import React, { useState, useEffect } from "react";
import { Label, Table, Header, Segment, Dropdown, Loader, Dimmer, Icon, Button, Modal, Grid } from 'semantic-ui-react'
import { DASHBOARD, NUMBERFORMAT } from '../../data/constants';
import { softledger } from "../../data/endpoints/gql";
import { getClient } from "../../data/init-apollo";
import moment from 'moment';
import AccountingGraph from './AccountingGraph';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './Accounting.css'

const accountingClient = getClient(DASHBOARD);

function Accounting(props) {

  const [AccountingData, setAccountingData] = useState([]);
  const [accountingSum, setAccountingSum] = useState([]);
  const [accountingLabels, setAccountingLabels] = useState([]);
  const [accountingNulls, setAccountingNulls] = useState([]);
  const [accountingType, setAccountingType] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [chosenSort, setChosenSort] = useState('');
  const [direction, setDirection] = useState(false);
  const [singleInvoice, setSingleInvoice] = useState(false);
  const [singleInvoiceFull, setSingleInvoiceFull] = useState(false);
  const [startDate, setStartDate] = useState(new Date);
  const [endDate, setEndDate] = useState(new Date);
  const [loader, setLoader] = useState(false);
  const [title, setTitle] = useState(false);
  const [visualize, setVisualize] = useState(false);

  const queryAccounting = () => {
    setAccountingSum([])
    accountingClient
      .query({
        query: softledger,
        variables: {
          filters: {
            "status": accountingNulls,
            "LocationId": props.primaryLocation.slLocationId,
            [accountingType == 'invoices' ? "invoiceDate" : 'paymentDate']:
            {
              "$and": {
                "$gte": moment(startDate).format('YYYY-MM-DD'),
                "$lte": moment(endDate).format('YYYY-MM-DD')
              }
            }
          },
          endpoint: accountingType,
          offset: 0,
          limit: 1000,
        },
      })
      .then((res) => {
        const accountingData = res.data.softledger.data
        const accountingDataBase = res.data.softledger
        setAccountingData(accountingData)
        if (accountingType == "payments") {
          setAccountingSum([accountingDataBase.rentTotal, accountingDataBase.pointsTotal, accountingDataBase.concessionsTotal])
        }
        if (accountingType == "invoices") {
          calculateFunction(accountingData)
        }
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
      });
  }

  const querySingleAccounting = () => {
    accountingClient
      .query({
        query: softledger,
        variables: {
          filters: {
            "id": singleInvoice._id,
          },
          endpoint: "invoices",
          offset: 0,
          limit: 1000,
        },
      })
      .then((res) => {
        const accountingData = res.data.softledger.data[0]
        setSingleInvoice(false)
        setSingleInvoiceFull(accountingData)
        setOpenModal(true)
      })
      .catch((error) => {
        setLoader(false)
      });
  }

  useEffect(() => {
    if (singleInvoice !== false) {
      querySingleAccounting()
    }
  }, [singleInvoice]);

  // useEffect(() => {
  //   if(typeof singleInvoiceFull =="object"){
  //     console.log(singleInvoiceFull)
  //    setOpenModal(true)
  //   }
  // },[singleInvoiceFull]);

  useEffect(() => {
    setAccountingNulls([])
    setAccountingData([])
  }, [accountingType]);


  const sortCol = (sortVal) => {
    switch (sortVal) {
      case "Due":
        setChosenSort(sortVal)
        direction ? setDirection(false) : setDirection(true)
        setAccountingData(direction ? AccountingData.sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate)) : AccountingData.reverse((a, b) => new Date(a.dueDate) - new Date(b.dueDate)))
        break;
      case "Amount":
        setChosenSort(sortVal)
        direction ? setDirection(false) : setDirection(true)
        setAccountingData(direction ? AccountingData.sort((a, b) => parseFloat(a.amount) - parseFloat(b.amount)) : AccountingData.reverse((a, b) => parseFloat(a.amount) - parseFloat(b.amount)))
        break;
      case "Unpaid":
        setChosenSort(sortVal)
        direction ? setDirection(false) : setDirection(true)
        setAccountingData(direction ? AccountingData.sort((a, b) => parseFloat(a.amountPayable) - parseFloat(b.amountPayable)) : AccountingData.reverse((a, b) => parseFloat(a.amountPayable) - parseFloat(b.amountPayable)))
        break;
      case "Status":
        setChosenSort(sortVal)
        direction ? setDirection(false) : setDirection(true)
        setAccountingData(direction ? AccountingData.sort((a, b) => a.status.localeCompare(b.status)) : AccountingData.reverse((a, b) => a.status.localeCompare(b.status)))
        break;
      case "Customer":
        setChosenSort(sortVal)
        direction ? setDirection(false) : setDirection(true)
        setAccountingData(direction ? AccountingData.sort((a, b) => a.Agent.name.localeCompare(b.Agent.name)) : AccountingData.reverse((a, b) => a.Agent.name.localeCompare(b.Agent.name)))
        break;
      case "Memo":
        setChosenSort(sortVal)
        direction ? setDirection(false) : setDirection(true)
        setAccountingData(direction ? AccountingData.sort((a, b) => a.memo.localeCompare(b.memo)) : AccountingData.reverse((a, b) => a.memo.localeCompare(b.memo)))
        break;
      case "Number":
        setChosenSort(sortVal)
        direction ? setDirection(false) : setDirection(true)
        setAccountingData(direction ? AccountingData.sort((a, b) => parseFloat(a.number) - parseFloat(b.number)) : AccountingData.reverse((a, b) => parseFloat(a.number) - parseFloat(b.number)))
        break;
      case "Type":
        setChosenSort(sortVal)
        direction ? setDirection(false) : setDirection(true)
        setAccountingData(direction ? AccountingData.sort((a, b) => a.type.localeCompare(b.type)) : AccountingData.reverse((a, b) => a.type.localeCompare(b.type)))
        break;
      case "Ref":
        setChosenSort(sortVal)
        direction ? setDirection(false) : setDirection(true)
        setAccountingData(direction ? AccountingData.sort((a, b) => a.reference.localeCompare(b.reference)) : AccountingData.reverse((a, b) => a.reference.localeCompare(b.reference)))
        break;
      case "InvoiceDate":
        setChosenSort(sortVal)
        direction ? setDirection(false) : setDirection(true)
        setAccountingData(direction ? AccountingData.sort((a, b) => new Date(a.invoiceDate) - new Date(b.invoiceDate)) : AccountingData.reverse((a, b) => new Date(a.invoiceDate) - new Date(b.invoiceDate)))
        break;
      case "Payment":
        setChosenSort(sortVal)
        direction ? setDirection(false) : setDirection(true)
        setAccountingData(direction ? AccountingData.sort((a, b) => new Date(a.paymentDate) - new Date(b.paymentDate)) : AccountingData.reverse((a, b) => new Date(a.paymentDate) - new Date(b.paymentDate)))
        break;
      case "Posted":
        setChosenSort(sortVal)
        direction ? setDirection(false) : setDirection(true)
        setAccountingData(direction ? AccountingData.sort((a, b) => new Date(a.postedDate) - new Date(b.postedDate)) : AccountingData.reverse((a, b) => new Date(a.postedDate) - new Date(b.postedDate)))
        break;
    }
  }

  const calculateFunction = (data) => {
    const objectValue = NUMBERFORMAT.format(data.reduce((a, b) => parseFloat(a) + parseFloat(b.amount), 0))
    console.log(data, objectValue, accountingSum)
    setAccountingSum([])
    setAccountingSum([parseFloat(objectValue.replace(/,/g, ''))])
  }

  const clearData = () => {
    setAccountingData([])
    setAccountingSum([])
    setAccountingLabels([])
    setAccountingNulls([])
    setSingleInvoiceFull(false)
    setStartDate(new Date)
    setEndDate(new Date)
  }


  return (
    <div className="accounting">
      <Segment >
        <Grid columns='2' relaxed style={{ width: '100%' }}>
          <Grid.Column width='8' style={{ width: '100%' }}>
            <div style={{ display: 'flex' }}>
              <Dropdown text='Type' className="dropdown-global">
                <Dropdown.Menu>
                  <Dropdown.Item disabled={accountingNulls.includes('invoices')} text='Invoices' onClick={() => setAccountingType('invoices')} />
                  <Dropdown.Item disabled={accountingNulls.includes('payments')} text='Payments' onClick={() => setAccountingType('payments')} />
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown text='Status' className="dropdown-global">
                <Dropdown.Menu>
                  {
                    accountingType == 'invoices' &&
                    <>
                      <Dropdown.Item disabled={accountingNulls.includes('created')} text='Created' onClick={() => setAccountingNulls(oldArr => [...oldArr, 'created'])} />
                      <Dropdown.Item disabled={accountingNulls.includes('issued')} text='Issued' onClick={() => setAccountingNulls(oldArr => [...oldArr, 'issued'])} />
                      <Dropdown.Item disabled={accountingNulls.includes('partialPayment')} text='Partial' onClick={() => setAccountingNulls(oldArr => [...oldArr, 'partialPayment'])} />
                      <Dropdown.Item disabled={accountingNulls.includes('paid')} text='Paid' onClick={() => setAccountingNulls(oldArr => [...oldArr, 'paid'])} />
                      <Dropdown.Item disabled={accountingNulls.includes('void')} text='Void' onClick={() => setAccountingNulls(oldArr => [...oldArr, 'void'])} />
                    </>
                  }
                  {
                    accountingType == 'payments' &&
                    <>
                      <Dropdown.Item disabled={accountingNulls.includes('approved')} text='Approved' onClick={() => setAccountingNulls(oldArr => [...oldArr, 'approved'])} />
                      <Dropdown.Item disabled={accountingNulls.includes('created')} text='Created' onClick={() => setAccountingNulls(oldArr => [...oldArr, 'created'])} />
                      <Dropdown.Item disabled={accountingNulls.includes('voided')} text='Voided' onClick={() => setAccountingNulls(oldArr => [...oldArr, 'voided'])} />
                    </>
                  }
                </Dropdown.Menu>
              </Dropdown>

              <Button onClick={() => clearData()}>Clear</Button>
              <Button onClick={() => queryAccounting()}>Search</Button>
              <div>
                <div style={{ display: 'flex' }}>
                  <span style={{ paddingRight: '10px' }}>Start</span>
                  <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                </div>
                <div style={{ display: 'flex' }}>
                  <span style={{ paddingRight: '17px' }}>End</span>
                  <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
                </div>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column width='8' stretched>
            {/* {AccountingData.length !==0 && <Button floated="right" onClick={() => setVisualize(!visualize?true:false)}>Visualize</Button>}  */}
            <div style={{ float: 'right' }}>
              {accountingType && <span style={{ textTransform: 'capitalize' }}>{accountingType} - </span>}
              {accountingNulls.length !== 0 && <span style={{ textTransform: 'capitalize' }}>{accountingNulls && accountingNulls.join(', ')} - </span>}
              {<span style={{ textTransform: 'capitalize' }}>{moment(startDate).format('MM/DD/YYYY')} - </span>}
              {<span style={{ textTransform: 'capitalize' }}>{moment(endDate).format('MM/DD/YYYY')}</span>}
            </div>
          </Grid.Column>
        </Grid>
      </Segment>
      {
        visualize ?
          <AccountingGraph title={accountingLabels} AccountingData={AccountingData} />
          :
          <Segment className="table-segment-global">
            {
              loader ?
                <>
                  <Dimmer active>
                    <Loader />
                  </Dimmer>
                </>
                :
                <>
                  <Header textAlign="center" className="header-global">{
                    accountingLabels.map(label => {
                      return (
                        <Label as='a' color={label.end == 'invoices' && 'brown' || label.end == 'bills' && 'blue' || label.end == 'cash' && 'green'} className="header-global">
                          {label.end}
                          <Label.Detail className="header-global">{label.filter}</Label.Detail>
                        </Label>
                      )
                    })
                  }</Header>
                  <Table celled>
                    <Table.Header>
                      <Table.Row>
                        {
                          accountingType == 'invoices' &&
                          <>
                            <Table.HeaderCell width="2" onClick={() => sortCol("Number")}>Number {chosenSort == "Number" && <Icon name={`arrow alternate circle ${direction ? "up" : "down"}`} />}</Table.HeaderCell>
                            <Table.HeaderCell width="2" onClick={() => sortCol("Status")}>Status {chosenSort == "Status" && <Icon name={`arrow alternate circle ${direction ? "up" : "down"}`} />}</Table.HeaderCell>
                            <Table.HeaderCell width="2" onClick={() => sortCol("Type")}>Type {chosenSort == "Type" && <Icon name={`arrow alternate circle ${direction ? "up" : "down"}`} />}</Table.HeaderCell>
                            <Table.HeaderCell width="2" onClick={() => sortCol("Ref")} >Ref {chosenSort == "Ref" && <Icon name={`arrow alternate circle ${direction ? "up" : "down"}`} />}</Table.HeaderCell>
                            <Table.HeaderCell width="2" onClick={() => sortCol("InvoiceDate")}>Invoice Date {chosenSort == "Invoice Date" && <Icon name={`arrow alternate circle ${direction ? "up" : "down"}`} />}</Table.HeaderCell>
                            <Table.HeaderCell width="2" onClick={() => sortCol("Customer")}>Customer {chosenSort == "Customer" && <Icon name={`arrow alternate circle ${direction ? "up" : "down"}`} />}</Table.HeaderCell>
                            <Table.HeaderCell width="2" onClick={() => sortCol("Amount")}>Amount {chosenSort == "Amount" && <Icon name={`arrow alternate circle ${direction ? "up" : "down"}`} />}</Table.HeaderCell>
                            <Table.HeaderCell width="2" onClick={() => sortCol("Unpaid")}>Unpaid {chosenSort == "Unpaid" && <Icon name={`arrow alternate circle ${direction ? "up" : "down"}`} />}</Table.HeaderCell>
                          </>
                        }
                        {
                          accountingType == 'payments' &&
                          <>
                            <Table.HeaderCell width="1" onClick={() => sortCol("Number")}>Number {chosenSort == "Number" && <Icon name={`arrow alternate circle ${direction ? "up" : "down"}`} />}</Table.HeaderCell>
                            <Table.HeaderCell width="2" onClick={() => sortCol("Type")}>Type {chosenSort == "Type" && <Icon name={`arrow alternate circle ${direction ? "up" : "down"}`} />}</Table.HeaderCell>
                            <Table.HeaderCell width="2" onClick={() => sortCol("Status")}>Status {chosenSort == "Status" && <Icon name={`arrow alternate circle ${direction ? "up" : "down"}`} />}</Table.HeaderCell>
                            <Table.HeaderCell width="2" onClick={() => sortCol("Invoice")} >Invoice {chosenSort == "Invoice" && <Icon name={`arrow alternate circle ${direction ? "up" : "down"}`} />}</Table.HeaderCell>
                            <Table.HeaderCell width="2" onClick={() => sortCol("Ref")}>Customer {chosenSort == "Ref" && <Icon name={`arrow alternate circle ${direction ? "up" : "down"}`} />}</Table.HeaderCell>
                            <Table.HeaderCell width="2" onClick={() => sortCol("Payment")}>Payment {chosenSort == "Payment" && <Icon name={`arrow alternate circle ${direction ? "up" : "down"}`} />}</Table.HeaderCell>
                            <Table.HeaderCell width="2" onClick={() => sortCol("Posted")}>Posted {chosenSort == "Posted" && <Icon name={`arrow alternate circle ${direction ? "up" : "down"}`} />}</Table.HeaderCell>
                            <Table.HeaderCell width="2" onClick={() => sortCol("Memo")}>Memo {chosenSort == "Memo" && <Icon name={`arrow alternate circle ${direction ? "up" : "down"}`} />}</Table.HeaderCell>
                            <Table.HeaderCell width="1" onClick={() => sortCol("Amount")}>Amount {chosenSort == "Amount" && <Icon name={`arrow alternate circle ${direction ? "up" : "down"}`} />}</Table.HeaderCell>
                          </>
                        }
                      </Table.Row>
                    </Table.Header>
                  </Table>
                  <div className='scrollable-table-global'>
                    <Table celled compact striped>
                      <Table.Body>
                        {
                          AccountingData.map(item => {
                            return (
                              <Table.Row>
                                {
                                  accountingType == 'invoices' &&
                                  <>
                                    <Table.Cell width="2" onClick={() => setSingleInvoice(item)}><a style={{ color: 'blue' }}>{item.number}</a></Table.Cell>
                                    <Table.Cell width="2">{item.status}</Table.Cell>
                                    <Table.Cell width="2">{item.type}</Table.Cell>
                                    <Table.Cell width="2">{item.reference}</Table.Cell>
                                    <Table.Cell width="2">{item.invoiceDate}</Table.Cell>
                                    <Table.Cell width="2">{item.Agent.name && item.Agent.name}</Table.Cell>
                                    <Table.Cell width="2">${item.amount}</Table.Cell>
                                    <Table.Cell width="2">${item.amountPayable}</Table.Cell>
                                  </>
                                }
                                {
                                  accountingType == 'payments' &&
                                  <>
                                    <Table.Cell width="1">{item.number}</Table.Cell>
                                    <Table.Cell width="2">{item.notes}</Table.Cell>
                                    <Table.Cell width="2">{item.status}</Table.Cell>
                                    <Table.Cell width="2">{item.Invoice && item.Invoice.number}</Table.Cell>
                                    <Table.Cell width="2">{item.Invoice && item.Invoice.Agent && item.Invoice.Agent.name}</Table.Cell>
                                    <Table.Cell width="2">{item.paymentDate}</Table.Cell>
                                    <Table.Cell width="2">{item.postedDate}</Table.Cell>
                                    <Table.Cell width="2">{item.memo}</Table.Cell>
                                    <Table.Cell width="1">${item.amount}</Table.Cell>
                                  </>
                                }
                              </Table.Row>
                            )
                          })
                        }
                      </Table.Body>
                    </Table>
                  </div>
                  <Table celled >
                    <Table.Footer>
                      <Table.Row>
                        {
                          accountingType == "payments" ?
                            <>
                              <Table.HeaderCell width="4"></Table.HeaderCell>
                              <Table.HeaderCell width="4">Rent: ${accountingSum[0]}</Table.HeaderCell>
                              <Table.HeaderCell width="4">Points: ${accountingSum[1]}</Table.HeaderCell>
                              <Table.HeaderCell width="4">Concessions: ${accountingSum[2]}</Table.HeaderCell></> :
                            <>
                              <Table.HeaderCell width="4"></Table.HeaderCell>
                              <Table.HeaderCell width="4"></Table.HeaderCell>
                              <Table.HeaderCell width="4"></Table.HeaderCell>
                              <Table.HeaderCell width="4">${NUMBERFORMAT.format(accountingSum.reduce((a, b) => parseFloat(a) + parseFloat(b), 0))}</Table.HeaderCell></>
                        }
                      </Table.Row>
                    </Table.Footer>
                  </Table>
                </>
            }
          </Segment>
    }
    <Modal
    className="semanticModal paybillsmodaloverview"
    size="large"
    centered
      onClose={() => setOpenModal(false)}
      onOpen={() => setOpenModal(true)}
      open={openModal}
    >
      <Modal.Header>
        Invoice #
        {singleInvoiceFull.number}{" "}
      </Modal.Header>
      <Modal.Content className="modalContent">
        <Modal.Description>
          <Grid columns={2}>
              <Grid.Column>
                <Table compact>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Location</Table.Cell>
                      <Table.Cell>
                        #{singleInvoiceFull.Agent && singleInvoiceFull.Agent.name}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Location ID</Table.Cell>
                      <Table.Cell>
                        {singleInvoiceFull.Agent && singleInvoiceFull.Agent.id}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Status</Table.Cell>
                      <Table.Cell style={{ textTransform: "Capitalize" }}>
                        {singleInvoiceFull.status}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Approved On</Table.Cell>
                      <Table.Cell>{singleInvoiceFull.approved_on}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Approved By</Table.Cell>
                      <Table.Cell>{singleInvoiceFull.Agent && singleInvoiceFull.Agent.name}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Approved Email</Table.Cell>
                      <Table.Cell>{singleInvoiceFull.approved_email}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column>
                <Table compact>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>Amount</Table.Cell>
                      <Table.Cell>{singleInvoiceFull.amount}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Amount Due</Table.Cell>
                      <Table.Cell>${singleInvoiceFull.amountPayable}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Invoice Date</Table.Cell>
                      <Table.Cell>{singleInvoiceFull.invoiceDate}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Posting Date</Table.Cell>
                      <Table.Cell>{singleInvoiceFull.postingDate}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>Location</Table.Cell>
                      <Table.Cell>
                        {singleInvoiceFull.Location && singleInvoiceFull.Location.name}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>IC Location</Table.Cell>
                      <Table.Cell>{singleInvoiceFull.ICLocation}</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>AP Account</Table.Cell>
                      <Table.Cell>
                        {singleInvoiceFull.ARAccount && singleInvoiceFull.ARAccount.number} -{" "}
                        {singleInvoiceFull.ARAccount && singleInvoiceFull.ARAccount.name}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>External Id</Table.Cell>
                      <Table.Cell>{singleInvoiceFull.externalId}</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid>

            <Table padded="very" compact>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell width="2">Line #</Table.HeaderCell>
                  <Table.HeaderCell width="5">Description</Table.HeaderCell>
                  <Table.HeaderCell width="5">Ledger Account</Table.HeaderCell>
                  <Table.HeaderCell width="1">Unit Amount</Table.HeaderCell>
                  <Table.HeaderCell width="1">Quantity</Table.HeaderCell>
                  <Table.HeaderCell width="1">Tax</Table.HeaderCell>
                  <Table.HeaderCell width="1">Total</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
            </Table>
            <div style={{ maxHeight: "300px", overflowY: "overlay" }}>
              <Table padded="very" compact>
                <Table.Body>
                  {singleInvoiceFull.InvoiceLineItems &&
                    singleInvoiceFull.InvoiceLineItems.map((item) => {
                      return (
                        <Table.Row>
                          <Table.Cell width="2">{item._id}</Table.Cell>
                          <Table.Cell width="5">{item.description}</Table.Cell>
                          <Table.Cell width="5">
                            {item.LedgerAccount.number} -{" "}
                            {item.LedgerAccount.name}
                          </Table.Cell>
                          <Table.Cell width="1">{item.subtotal}</Table.Cell>
                          <Table.Cell width="1">{item.quantity}</Table.Cell>
                          <Table.Cell width="1">{item.taxAmount}</Table.Cell>
                          <Table.Cell width="1">{item.total}</Table.Cell>
                        </Table.Row>
                      );
                    })}
                </Table.Body>
              </Table>
            </div>

            <Grid columns={2}>
              <Grid.Column></Grid.Column>
              <Grid.Column>
                <Table padded="very" compact>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>{"Subtotal"}</Table.Cell>
                      <Table.Cell textAlign="right">
                        ${singleInvoiceFull.subtotal}
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell>Total</Table.Cell>
                      <Table.Cell textAlign="right">
                        ${singleInvoiceFull.amount}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid>
            <Segment.Group>
              <Segment>Notes</Segment>
              <Segment.Group>
                <Segment>{singleInvoiceFull.notes}</Segment>
              </Segment.Group>
            </Segment.Group>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions className="modalActions">
        <Button
          content="Close"
          labelPosition='right'
          icon='checkmark'
          onClick={() => setOpenModal(false)}
          positive
        />
      </Modal.Actions>
    </Modal>
    </div>
  );
}

export default Accounting;
