import React , {useState,useEffect} from "react";
import { Tab, Table, Header, Segment, Dropdown, Loader, Dimmer, Icon, Button } from 'semantic-ui-react'
import { DASHBOARD,NUMBERFORMAT } from '../../data/constants';
import { mixpanel } from "../../data/endpoints/gql";
import { getClient } from "../../data/init-apollo";
import moment from 'moment';
import  MixpanelGraph  from './MixpanelGraph';

const MixapanelClient = getClient(DASHBOARD);
function MixpanelDetails() {
  const [MixpanelData, setMixpanelData] = useState([]);
  const [chosenSort, setChosenSort] = useState('');
  const [direction, setDirection] = useState(false);
  const [loader, setLoader] = useState(false);
  const [title, setTitle] = useState(false);
  const [visualize, setVisualize] = useState(false);

    const queryMixapanel = (end, filter) => {
      setTitle(`${end} - ${'Log Ins'}`)
      setLoader(true)
      MixapanelClient
        .query({
          query: mixpanel,
          variables: {
            filters:{
                distinct_ids: ["15715041208762368"]
            },
            endpoint:end,
          },
        })
        .then((res) => {
          const MixpanelData = res.data.mixpanel.data
          setMixpanelData(MixpanelData)
          setLoader(false)
        })
        .catch((error) => {
          setLoader(false)
        });
    }
  const sortCol = (sortVal) => {
    switch(sortVal){
      case "Name":
        setChosenSort(sortVal)
        direction?setDirection(false):setDirection(true)
        setMixpanelData(direction?MixpanelData.sort((a, b) => a.$properties.Name.localeCompare(b.$properties.Name)):MixpanelData.reverse((a, b) => a.$properties.Name.localeCompare(b.$properties.Name)))
      break;
      case "Email":
        setChosenSort(sortVal)
        direction?setDirection(false):setDirection(true)
        setMixpanelData(direction?MixpanelData.sort((a, b) => a.$properties.Email.localeCompare(b.$properties.Email)):MixpanelData.reverse((a, b) => a.$properties.Email.localeCompare(b.$properties.Email)))
      break;
      case "City":
        setChosenSort(sortVal)
        direction?setDirection(false):setDirection(true)
        setMixpanelData(direction?MixpanelData.sort((a, b) => a.$properties.$city.localeCompare(b.$properties.$city)):MixpanelData.reverse((a, b) => a.$properties.$city.localeCompare(b.$properties.$city)))
      break;
      case "Country":
        setChosenSort(sortVal)
        direction?setDirection(false):setDirection(true)
        setMixpanelData(direction?MixpanelData.sort((a, b) => a.$properties.$country_code.localeCompare(b.$properties.$country_code)):MixpanelData.reverse((a, b) => a.$properties.$country_code.localeCompare(b.$properties.$country_code)))
      break;
      case "Seen":
        setChosenSort(sortVal)
        direction?setDirection(false):setDirection(true)
        setMixpanelData(direction?MixpanelData.sort((a, b) => new Date(a.$properties.$last_seen) - new Date(b.$properties.$last_seen)):MixpanelData.reverse((a, b) => new Date(a.$properties.$last_seen) - new Date(b.$properties.$last_seen)))
      break;
      case "Points":
        setChosenSort(sortVal)
        direction?setDirection(false):setDirection(true)
        setMixpanelData(direction?MixpanelData.sort((a, b) => parseFloat(a.$properties.Points) - parseFloat(b.$properties.Points)):MixpanelData.reverse((a, b) => parseFloat(a.$properties.Points) - parseFloat(b.$properties.Points)))
      break;
    }
  }
  
  
  return (
    <div className="Mixapanel">
      <Segment>
        <Dropdown text='Users' className="dropdown-global">
          <Dropdown.Menu>
            <Dropdown.Item text='Users'  onClick={() => queryMixapanel("users")}/>
          </Dropdown.Menu>
        </Dropdown>
        <Button floated="right" onClick={() => setVisualize(!visualize?true:false)}>Visualize</Button>
      </Segment> 
      {
        visualize?
       <MixpanelGraph title={title} MixpanelData={MixpanelData}/>
        :
      <Segment className="table-segment-global">
      {
        loader?
        <>
        <Dimmer active>
          <Loader />
        </Dimmer>
        </>
        :
        <>
      <Header textAlign="center" className="header-global">{title}</Header>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="3" onClick={() => sortCol("Name")}>Name {chosenSort =="Name" &&<Icon name={`arrow alternate circle ${direction?"up":"down"}`} />}</Table.HeaderCell>
            <Table.HeaderCell width="3" onClick={() => sortCol("Email")}>Email {chosenSort =="Email" &&<Icon name={`arrow alternate circle ${direction?"up":"down"}`} />}</Table.HeaderCell>
            <Table.HeaderCell width="3" onClick={() => sortCol("City")} >City {chosenSort =="City" &&<Icon name={`arrow alternate circle ${direction?"up":"down"}`} />}</Table.HeaderCell>
            <Table.HeaderCell width="3" onClick={() => sortCol("Country")}>Country {chosenSort =="Country" &&<Icon name={`arrow alternate circle ${direction?"up":"down"}`} />}</Table.HeaderCell>
            <Table.HeaderCell width="3" onClick={() => sortCol("Seen")}>Seen {chosenSort =="Seen" &&<Icon name={`arrow alternate circle ${direction?"up":"down"}`} />}</Table.HeaderCell>
            <Table.HeaderCell width="3" onClick={() => sortCol("Points")}>Points {chosenSort =="Points" &&<Icon name={`arrow alternate circle ${direction?"up":"down"}`} />}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      </Table>
      <div className='scrollable-table-global'>
      <Table celled compact striped>
        <Table.Body>
        {
          MixpanelData.map(data => {
          return (
            <Table.Row>
              <Table.Cell width="3">{data.$properties.Name}</Table.Cell>
              <Table.Cell width="3">{data.$properties.Email}</Table.Cell>
              <Table.Cell width="3">{data.$properties.$city}</Table.Cell>
              <Table.Cell width="3">{data.$properties.$country_code}</Table.Cell>
              <Table.Cell width="3">{data.$properties.$last_seen ?moment(data.$properties.$last_seen).format("MM/DD/YYYY"):"--"}</Table.Cell>
              <Table.Cell width="3">{data.$properties.Points}</Table.Cell>
            </Table.Row>
          )
          })
        }
        </Table.Body>
      </Table>
      </div>
      <Table celled >
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell width="3"></Table.HeaderCell>
            <Table.HeaderCell width="3"></Table.HeaderCell>
            <Table.HeaderCell width="3"></Table.HeaderCell>
            <Table.HeaderCell width="3"></Table.HeaderCell>
            <Table.HeaderCell width="3"></Table.HeaderCell>
            <Table.HeaderCell width="3">{NUMBERFORMAT.format(MixpanelData.reduce((a,b) => a + b.$properties.Points,0).toFixed(2))}</Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
      </>
    }
      </Segment>
    }
    </div>
  );
}

export default MixpanelDetails;
